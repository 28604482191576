<template>
    <div style="margin-top: 75px;text-align: center;padding: 20px 140px;">
        <h1 style="margin-bottom: 50px;">“2024海南省文创大赛”原创声明</h1>

        <div style="text-align: left; font-size: 20px;"> 为保护原创作者（“作者”）的合法权益，确保原创作品（“作品”）的独创性和专有性，根据我国《民法典》、《著作权法》等相关法律法规的规定，作者签署本声明。</div>
        <div style="text-align: left; font-size: 20px;">一、声明的目的和意义
            为明确作品的独创性和专有性，保护作者的合法权益，同时保障赛事组织单位和其他参赛者的合法权益。本声明的签署对于维护大赛的公平公正，促进文化创新和发展具有重要意义。</div>
        <div style="text-align: left; font-size: 20px;">
            二、声明的范围和地域范围
            1、本声明适用于作者所有在大赛中提交的作品，包括但不限于文字、图片、实物、音频、视频等表现形式。
            2、本声明适用于全球范围内，包括所有国家和地区。
        </div>
        <div style="text-align: left; font-size: 20px;">
            三、声明的内容
            1、作品归属
            作者保证自己是参赛作品的唯一所有者，拥有完全的著作权和相关权利，包括但不限于复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权等。
            2、原创性声明
            作者保证在大赛中提交的所有作品均为原创，不存在抄袭、剽窃、篡改等侵犯他人著作权或其他相关权利的行为。
            3、责任承担
            作者应承担因侵犯他人著作权或其他相关权利而引起的法律责任和损害赔偿责任，并承担因此给赛事组织单位和其他参赛者带来的任何损失和不良影响。
            4、解决方式
            对于因作品而引起的任何纠纷或法律责任，作者应积极解决，并承担由此产生的所有费用和法律责任。如果赛事组织单位因此受到任何损失或不良影响，作者应向赛事组织单位赔偿全部损失，包括赛事组织单位为主张权益而支出的诉讼费、律师费等相关费用。
        </div>
        <div style="text-align: left; font-size: 20px;">
            四、声明有效期
            本声明的有效期为永久，不设终止日期。
        </div>
        <div style="text-align: left; font-size: 20px;">
            五、声明的变更和解除
            本声明的变更和解除需经过赛事组织单位书面确认。
        </div>
        <div style="text-align: left; font-size: 20px;">
            六、争议解决
            1、如因本声明或与本声明相关的任何争议应首先由双方友好协商解决；协商不成的，任何一方均有权将争议提交至赛事组织单位所在地的有管辖权的人民法院诉讼解决。
            2、在争议解决期间，作者应继续履行本协议中不涉及争议的其他条款。
        </div>
        <div style="text-align: left; font-size: 20px;">
            七、其他事项
            1、本声明的签署程序：作者在2023“梦溪杯”宋韵文化创新大赛赛事官方网站作品上传勾选确认本声明，并按照系统提示填写相应的信息。赛事组织单位通过审核后，本声明即正式生效，生效日为赛事组织单位审核通过之日。
            2、本声明的语言要求：本声明以中文和英文两种语言签署，具有同等法律效力。如本协议的翻译存在任何解释上的不一致，以中文版为准。
        </div>
    </div>
</template>

<script>

export default {
    name: "cultureOne",
    mounted() { },
    created() { },
    methods: {}
};
</script>
<style lang='less'></style>