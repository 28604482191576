import { render, staticRenderFns } from "./science_child5.vue?vue&type=template&id=3aa95df6"
import script from "./science_child5.vue?vue&type=script&lang=js"
export * from "./science_child5.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports